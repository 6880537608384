import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from "../../../1.resources/3.images/logo/logo.png"
import Twitter from "../../../1.resources/3.images/images/twitter.webp";
import Telegram from "../../../1.resources/3.images/images/telegram.webp";
import Etherscan from "../../../1.resources/3.images/images/etherscan.png";
import Uniswap from "../../../1.resources/3.images/images/uniswap.png";
import Cmc from "../../../1.resources/3.images/images/cmc.png";

const Links = ({ }) => {

    useEffect(() => {
    }, [])
    return (
        <div className="flex justify-center gap-x-4">
            <a href="https://twitter.com/simpsonscoineth" target={"_blank"}>
                <img src={Twitter} className="w-10 h-10 rounded-full bg-[#1DA1F1] p-1" />
            </a>
            <a href="https://t.me/simpsonscoin_eth" target={"_blank"}>
                <img src={Telegram} className="w-10 h-10 rounded-full" />
            </a>
            <a href="https://etherscan.io/token/0xaa7d60d66d8a055ea9673e0c29f203c200a3d197" target={"_blank"}>
                <img src={Etherscan} className="w-10 h-10 rounded-full bg-white" />
            </a>
            <a href="" target={"_blank"}>
                <img src={Cmc} className="w-10 h-10 rounded-full bg-white" />
            </a>
            <a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xaA7D60d66D8a055ea9673E0C29f203C200a3D197" target={"_blank"}>
                <img src={Uniswap} className="w-10 h-10 rounded-full" />
            </a>

        </div>
    );

}


export default Links;
