import React, { useState, useEffect, useContext } from "react";
import Home from "./3.views/1.home/home";

const Main = ({ }) => {


    return (
        <div>
            <Home />
        </div >
    );

}

export default Main;


/* position: "absolute", top: "50%", transform: "translateY(-50%)" */