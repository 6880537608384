import React, { useState, useContext } from "react";
import BgImage from "../../1.resources/3.images/images/bg22.png";
import Bg2Image from "../../1.resources/3.images/images/bg2.png";
import Bg3Image from "../../1.resources/3.images/images/bg3.png";
import Header from "../0.global/header/header";
import Links from "../0.global/header/links";

const Home = ({ walletConnected, setWalletConnected }) => {

    return (
        <div>

            <div className="" style={{ width: window.innerWidth, backgroundImage: `url(${Bg2Image})`, backgroundPosition: "center center", backgroundRepeat: "none", backgroundSize: "cover" }}>
                <Header />
                <div className="py-4 flex justify-center pb-0 pt-20">
                    <div>
                        <p className="text-6xl md:text-9xl font-bold text-center">$SIMPSON</p>
                        <p className="text-2xl font-semibold text-center pt-4">Welcome to the greatest meme club!</p>

                        <div className="pt-6 md:hidden">
                            <Links />
                        </div>
                        <div className="flex justify-center pt-6">
                            <div className="bg-yellow-400 border border-yellow-500 rounded-full px-6 py-2 w-fit">
                                <p className="text-2xl font-bold">Buy Now</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="" style={{ width: window.innerWidth, height: window.innerHeight * 1, backgroundImage: `url(${BgImage})`, backgroundPosition: "center center", backgroundRepeat: "none", backgroundSize: "cover" }}>

            </div>
            <div className="" style={{ width: window.innerWidth, backgroundImage: `url(${Bg3Image})`, backgroundPosition: "center center", backgroundSize: "cover" }}>

                <div className="flex justify-center">
                    <div className="w-[1080px] bg-yellow-400 rounded-2xl h-fit px-10 py-20">
                        <p className="text-7xl font-semibold text-center">About</p>
                        <p className="text-2xl font-semibold text-center pt-4 px-10 md:px-20">$SIMPSON is the cryptocurrency inspired by The Simpsons! With a vibrant brand and innovative technology, $SIMPSON is the perfect choice for Simpsons fans and savvy investors alike. Join the club and be a part of the future of crypto!</p>


                        <p className="text-7xl font-semibold text-center pt-20">Tokenomics</p>
                        <p className="text-2xl font-semibold text-center pt-4">Total Supply: 420,690,000,000</p>

                        <p className="text-2xl font-semibold text-center pt-4">No Taxes, No Bullshit. It’s that simple.</p>
                    </div>
                </div>

                <div className="flex justify-center pt-20">
                    <div className="w-[1080px] bg-[#F9BCC4] rounded-2xl h-fit px-10 py-20">
                        <p className="text-7xl font-semibold text-center">Airdrop</p>

                        <p className="text-7xl font-semibold text-center pt-28">Coming Soon</p>

                    </div>
                </div>


                <div className="flex justify-center pb-10">

                    <div className="flex justify-center mt-20 w-[1080px] px-10">

                        <div>
                            <p className="text-2xl text-center text-white">$SIMPSON coin has no association with the animated sitcom 'The Simpsons'. This token is simply paying homage to a meme we all love and recognize.</p>

                            <p className="text-2xl text-center mt-8 text-white">$SIMPSON is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or roadmap. the coin is completely useless and for entertainment purposes only.</p>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    );

}

export default Home;
