import React from "react";
import ReactDom from "react-dom";
import App from "./app"
import SnackbarProvider from 'react-simple-snackbar'
import "./index.css";

ReactDom.render(
    <React.StrictMode>
        <SnackbarProvider>
            <App />
        </SnackbarProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
