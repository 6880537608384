import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from "../../../1.resources/3.images/logo/logo.png"
import Links from "./links";


const Header = ({ }) => {

    useEffect(() => {
    }, [])
    return (
        <div style={{}} className="hidden md:flex justify-center pt-6">
            <div className="flex justify-between items-center w-[1080px]">
                <div >
                    <a href="/" className="flex items-center gap-x-2">
                        <img src={Logo} className="w-14 h-14 rounded-full" />
                        <p className="text-2xl">$SIMPSON</p>
                    </a>
                </div>
                <Links />
            </div >
        </div >
    );

}


export default Header;
